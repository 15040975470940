import React from "react";

const Button = ({
  className,
  icon: IconComponent,
  title,
  loadingTitle,
  loading,
  ...props
}) => (
  <button
    type="submit"
    className={`btn ${className || "btn-primary btn-block"}`}
    {...props}
  >
    {loading ? (
      <div
        className="spinner-border spinner-border-sm mr-2"
        // style={{ marginBottom: 3 }}
      />
    ) : IconComponent ? (
      <IconComponent />
    ) : null}
    {loading ? loadingTitle || title : title}
  </button>
);

export default Button;

import React from "react";

function BadgeComponent({ status }) {
  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "successful":
        return "tw-bg-green-600 tw-text-white";
      case "cleared":
        return "tw-bg-blue-600 tw-text-white";
      case "paid":
        return "tw-bg-green-600 tw-text-white";
      case "expired":
        return "tw-bg-red-600 tw-text-white";
      case "pending":
        return "tw-bg-yellow-600 tw-text-white";
      case "failed":
        return "tw-bg-gray-600 tw-text-white";
      case "processing":
        return "tw-bg-orange-600 tw-text-white";
      case "cancelled":
        return "tw-bg-purple-600 tw-text-white";
      case "rejected":
        return "tw-bg-pink-600 tw-text-white";
      case "approved":
        return "tw-bg-teal-600 tw-text-white";
      default:
        return "tw-bg-gray-600 tw-text-white";
    }
  };

  return (
    <span
      className={`tw-px-4 py-2  tw-py-1 tw-rounded-md tw-text-sm tw-font-500 ${getStatusColor(
        status
      )}`}
    >
      {status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()}
    </span>
  );
}

export default BadgeComponent;

import FuelBuyerDashboardPageTitle from "../../../components/PageTitle";
import Loader from "components/shared/Loader";
import React, { useEffect, useState } from "react";
import { IoMdCheckmark } from "react-icons/io";
import { IoCloseSharp } from "react-icons/io5";
import { useParams, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { fetchFuelTransactionById } from "services/network-v2/fuel";

const FuelBuyerOrderPage = () => {
  const history = useHistory();
  const [transactionData, setTransactionData] = useState(null);
  const { id } = useParams();

  const fetchData = async () => {
    const response = await fetchFuelTransactionById(id);
    return response?.data;
  };

  useEffect(() => {
    const getData = async () => {
      const data = await fetchData();
      setTransactionData(data);
    };

    getData();
  }, []);

  if (!transactionData) {
    return <Loader />;
  }

  return (
    <div className="tw-mt-[3em] tw-flex tw-items-center tw-justify-center tw-p-4">
      <div className="tw-relative tw-bg-white tw-rounded-[2rem] tw-shadow-lg tw-max-w-md tw-w-full tw-pt-16 tw-px-8 tw-pb-8 tw-space-y-8">
        <div className="tw-absolute tw--top-8 tw-left-1/2 tw-transform tw--translate-x-1/2">
          {transactionData.status.toLowerCase() === "successful" && (
            <div
              className="tw-bg-green-600 tw-h-[80px] tw-w-[80px] tw-flex tw-items-center
              tw-justify-center tw-rounded-full tw-shadow-lg"
            >
              <IoMdCheckmark className="text-white w-10 h-10" size={"2.5em"} />
            </div>
          )}

          {transactionData.status.toLowerCase() === "pending" && (
            <div
              className="tw-bg-yellow-600 tw-h-[80px] tw-w-[80px] tw-flex tw-items-center
              tw-justify-center tw-rounded-full tw-shadow-lg"
            >
              <IoMdCheckmark className="text-white w-10 h-10" size={"2.5em"} />
            </div>
          )}
        </div>
        <div className="tw-text-center tw-space-y-2">
          <h2 className="tw-text-2xl tw-font-bold tw-text-gray-800">
            Fuel Purchase Successful!
          </h2>
          <p className="tw-text-sm tw-text-gray-500">
            Your fuel purchase transaction is complete,
            <br />
            information is listed below
          </p>
        </div>
        <div className="tw-text-center tw-space-y-1">
          <div className="tw-text-3xl tw-font-bold tw-text-gray-800">
            {transactionData.code}
          </div>
          <div className="tw-text-2xl tw-font-semibold tw-text-gray-800">
            ₦ {transactionData.price.toLocaleString()}
            <span className="tw-text-lg">.00</span>
          </div>
          <div className="tw-text-xs tw-text-gray-500">
            {new Date(transactionData.createdAt).toLocaleDateString()}
          </div>
        </div>
        <div className="tw-border-t tw-pt-6">
          <h3 className="tw-text-lg tw-font-semibold tw-text-gray-800 tw-mb-4">
            Receivers Details
          </h3>
          <div className="tw-space-y-3 tw-text-sm">
            <div className="tw-flex tw-justify-between">
              <span className="tw-text-gray-500">Name of Driver:</span>
              <span className="tw-text-gray-800">
                {transactionData.customer.firstName}{" "}
                {transactionData.customer.lastName}
              </span>
            </div>
            <div className="tw-flex tw-justify-between">
              <span className="tw-text-gray-500">Name of Vehicle:</span>
              <span className="tw-text-gray-800">
                {transactionData.expectedUnitOrders[0].product.name}
              </span>
            </div>
            <div className="tw-flex tw-justify-between">
              <span className="tw-text-gray-500">Vehicle Plate No.:</span>
              <span className="tw-text-gray-800">AV 440 LND</span>
            </div>
            <div className="tw-flex tw-justify-between">
              <span className="tw-text-gray-500">Vehicle Color:</span>
              <span className="tw-text-gray-800">White</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FuelBuyerOrderPage;

import { thousandFormatter } from "../../../lib/number";
import EventShareBox from "../EventShareBox";
import "./styles.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "@iconify/react";
import { EventFallbackImage } from "assets/images";
import { ImageComponent } from "components/shared/ImageComponent";
import { findCurrencySymbolByCode } from "lib/country";
import {
  getEventCheapestTicket,
  getEventPath,
  getEventThumbnailUrl,
  isEventSavedByUser,
} from "lib/event";
import { DateTime } from "luxon";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { addEventToSavedEventsRequest } from "state/slices/events";
import { getOneLinerErrorMessage, hyphenate } from "utilities";

const EventCard = ({ event, smallStyle = false, isUserEvent = false }) => {
  const { user } = useSelector((state) => state.users.data);

  const image = getEventThumbnailUrl(event);

  const cheapestTicket = getEventCheapestTicket(event);

  const eventIsFree =
    !event.ticketTypes?.filter((ticketType) => ticketType.price !== 0)
      ?.length !== 0;

  const history = useHistory();
  const token = localStorage.getItem("token");
  const [isSaved, setSaved] = useState(false);
  const [shareDiv, setShareDiv] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const [isSavingEvent, setIsSavingEvent] = useState(false);

  const dispatch = useDispatch();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const onLikeEvent = async (e) => {
    e.preventDefault();

    if (!token) {
      history.push("/auth/login");
      return;
    }

    setSaved((prevState) => !prevState);
    const payload = { eventId: event.id, data: event.isSaved || isSaved };

    setIsSavingEvent(true);

    const resultAction = await dispatch(addEventToSavedEventsRequest(payload));
    if (addEventToSavedEventsRequest.fulfilled.match(resultAction)) {
      setIsSavingEvent(false);
      toast.success(resultAction.payload.message);
    } else {
      setIsSavingEvent(false);
      setSaved((prevState) => !prevState);
      if (resultAction.payload.message) {
        toast.error(resultAction.payload.message);
      } else {
        toast.error(
          getOneLinerErrorMessage(resultAction.payload) ||
            resultAction.error?.message ||
            "An error occurred, please try again."
        );
      }
    }
  };

  const displayShareDiv = (e) => {
    e.preventDefault();

    setShareDiv(!shareDiv);
  };

  const isEventSaved = useSelector((state) =>
    isEventSavedByUser(state, event.id)
  );

  useEffect(() => {
    setSaved(isEventSaved);
  }, [isEventSaved]);

  const eventBelongsToUser =
    (event.creator?.id && event.creator?.id === user?.id) || isUserEvent;

  const eventPath = getEventPath(event);

  const userEventPath = `/dashboard/organiser/events/${event.id}/dashboard`;

  return (
    <div className="event-card-component tw-items-stretch" key={event}>
      <div className="card h-100 tw-max-h-full tw-border">
        <Link to={eventPath} className="card-link tw-p-0">
          <div className="card-image-container">
            <Suspense
              fallback={
                <div className="card-img-top card-image d-flex flex-row justify-content-center align-items-center tw-bg-black tw-animate-pulse">
                  Loading image...
                </div>
              }
            >
              <ImageComponent
                className="card-img-top card-image img-fluid"
                src={image}
              />
            </Suspense>
          </div>

          {!event.isIG && (
            <div className="event-category-label">
              <span className="bg-white text-black-30 px-4 py-2 text-sm text-white tw-font-semibold mt-2">
                {event?.category?.name}
              </span>
            </div>
          )}

          <div className="event-category-icons mt-2">
            <div className="event-icons">
              <button
                type="button"
                className="btn-round bg-white rounded-circle"
                onClick={displayShareDiv}
              >
                <Icon icon="bi:share-fill" color="black" height={16} />
              </button>
            </div>
            {!event.isIG && (
              <div className="event-icons">
                <button
                  type="button"
                  className={`btn-round bg-white rounded-circle ${
                    event.isSaved || isSaved ? "text-red" : "text-black"
                  } `}
                  onClick={onLikeEvent}
                  disabled={isSavingEvent}
                >
                  <Icon
                    color="black"
                    fontSize={"18px"}
                    icon={
                      event.isSaved || isSaved ? "el:heart" : "el:heart-empty"
                    }
                  />
                </button>
              </div>
            )}
          </div>

          <div
            className="car-body row tw-max-w-full tw-max-h-full"
            style={{ margin: 0 }}
          >
            {!event.isIG && (
              <div className="col-2 tw-flex tw-flex-col tw-items-center tw-justify-center bg-gray-60 tw-text-black">
                <span className="tw-text-3xl">
                  {DateTime.fromJSDate(
                    new Date(event.dates?.[0].startDate)
                  ).toFormat("LLL")}
                </span>
                <b className="tw-text-5xl tw-font-bold">
                  {DateTime.fromJSDate(
                    new Date(event.dates?.[0].startDate)
                  ).toFormat("dd")}
                </b>
              </div>
            )}
            <div
              className={`${
                event.isIG ? "col-12" : "col-10"
              }  card-content-two tw-py-5 tw-text-black tw-relative`}
            >
              {event.isResold && (
                <div className="tw-absolute tw-top-0 tw-mt-1.5 tw-w-fit tw-p-[1px] tw-px-4 tw-rounded-sm tw-bg-green-400 tw-text-white tw-text-[9px]">
                  Reseller
                </div>
              )}

              <div
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                className="card-c-t-c row tw-mt-1.5"
              >
                {isHovered ? (
                  <>
                    <h2 className="col-12 tw-font-semibold tw-text-xl text-grey-300">
                      {event.title}
                    </h2>
                  </>
                ) : (
                  <>
                    <h2 className="col-12 font-weight-bold tw-text-xl text-grey-300 tw-truncate">
                      {event.title}
                    </h2>
                  </>
                )}
              </div>

              <div className="d-flex flex-row justify-start items-center tw--mt-1">
                <Icon
                  icon="clarity:map-marker-line"
                  height={16}
                  className="text-sm mr-2"
                />
                <span
                  className="text-sm tw-text-black address-span tw-semibold text-nowrap text-truncate pointer"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={`${event.venueDetails?.city}, ${event.venueDetails?.state}`}
                >
                  {!event.isIG ? (
                    <>
                      {event.venueDetails?.city}, {event.venueDetails?.state}
                    </>
                  ) : (
                    <>{event.venueDetails.venueName}</>
                  )}
                </span>
              </div>

              <div className="mt-1 bg-white d-flex flex-row justify-content-between align-items-center">
                {eventIsFree ? (
                  <div className=" tw-text-black">
                    <small className="tw-text-sm">From:</small>
                    <div className="tw--mt-2">
                      <span className="tw-text-sm font-weight-bold tw-uppercase">{`${
                        !event.isIG
                          ? findCurrencySymbolByCode(event.currency)
                          : findCurrencySymbolByCode(cheapestTicket.currency) ||
                            ""
                      }${thousandFormatter(cheapestTicket.price || 0)}`}</span>
                    </div>
                  </div>
                ) : (
                  <div className="free">FREE</div>
                )}
                <div className="d-inline-flex justify-content-start align-items-center">
                  <Link
                    to={
                      eventBelongsToUser
                        ? userEventPath
                        : `${eventPath}/book-ticket`
                    }
                  >
                    <button
                      type="button"
                      className="btn btn-accent btn-sm p-2 px-3"
                    >
                      {eventBelongsToUser ? "Manage" : "Get Ticket"}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Link>
        <div
          className="card-footer mt-auto bg-white d-flex flex-row justify-content-between align-items-center"
          style={{ height: 0, padding: 0, margin: 0 }}
        >
          <div className="d-inline-flex justify-content-start align-items-center tw-relative">
            <EventShareBox
              shareDiv={shareDiv}
              url={`${process.env.REACT_APP_WEBSITE_URL}event/${
                event.eventSerial || event.id
              }/${hyphenate(event.title)}`}
              className="popup-div"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;

import axios from "../api";

export const getAllStores = () => {
  return axios.get("/api/stores/");
};

export const createStore = (payload) => {
  return axios.post("/api/stores/create/", payload);
};

export const getStoreDash = (payload) => {
  return axios.get(`/api/stores/${payload}/`);
};

export const getStoreUserRole = (storeId) => {
  return axios.get(`/api/stores/${storeId}/role/`);
};

export const getStoreCategory = (payload) => {
  if (payload.q == "") {
    return axios.get(
      `api/stores/${payload.storeId}/categories/?page=${payload.page}&perPage=${payload.perPage}`
    );
  } else {
    return axios.get(
      `api/stores/${payload.storeId}/categories/?q=${payload.q}`
    );
  }
};

export const AllStoreCategory = () => {
  return axios.get(`api/stores/category_choices/?q=all`);
};

export const deleteStoreCategory = (payload) => {
  return axios.delete(
    `api/stores/${payload.storeId}/category/${payload.catId}/delete/`
  );
};

export const editStoreCategory = (payload) => {
  return axios.put(
    `api/stores/${payload.storeId}/category/edit/`,
    payload.formPayload
  );
};

export const addStoreCategory = (payload) => {
  return axios.post(`api/stores/category/create/`, payload);
};

export const uploadDocument = (payload) => {
  return axios.put("/api/stores/create/", payload);
};

export const getStoreItems = (payload) => {
  return axios.get(
    `/api/stores/${payload.storeId}/items/?page=${payload.page}&perPage=${payload.perPage}`
  );
};

export const editStoreItem = (payload) => {
  return axios.put(`/api/stores/item/edit/`, payload);
};

export const deleteStoreItem = (payload) => {
  return axios.delete(
    `api/stores/${payload.storeId}/item/${payload.itemId}/delete/`
  );
};

export const createItem = (payload) => {
  return axios.post(`api/stores/item/create/`, payload);
};

export const editItem = (payload) => {
  return axios.put(`api/stores/item/edit/`, payload);
};

export const getStoreUserList = (payload) => {
  return axios.get(
    `api/stores/${payload.storeId}/users/?page=${payload.page}&perPage=${payload.perPage}`
  );
};

export const assignUserList = (payload) => {
  return axios.post(
    `api/stores/${payload.storeId}/users/`,
    payload.formPayload
  );
};

export const editStoreUserList = (payload) => {
  return axios.put(`api/stores/${payload.storeId}/users/`, payload.formPayload);
};

export const getStoreOrder = (payload) => {
  return axios.get(
    `api/stores/${payload.storeId}/orders/?page=${payload.page}&perPage=${payload.perPage}`
  );
};

export const deleteStoreUserRequest = (payload) => {
  return axios.delete(
    `api/stores/${payload.storeId}/users/${payload.userId}/delete/`
  );
};

export const getStorePermissionsRequest = (payload) => {
  return axios.get(`api/stores/${payload.storeId}/permissions`);
};

export const getStoreRoles = () => {
  return axios.get(`api/stores/roles/`);
};

export const createStoreRoles = (payload) => {
  return axios.post(
    `api/stores/${payload.storeId}/roles/`,
    payload.formPayload
  );
};

export const editStoreRoles = (payload) => {
  return axios.put(`api/stores/${payload.storeId}/roles/`, payload.formPayload);
};

export const getStoreSales = (storeId) => {
  return axios.get(`/api/stores/${storeId}/sales/`);
};

export const getStoreOrdersPDF = (storeId) => {
  return axios.get(`/api/stores/order/${storeId}/pdf/`);
};

export const updateOrderStatusRequest = (payload) => {
  return axios.put(`/api/stores/order/`, payload);
};

export const getStoreDetails = (storeId) => {
  return axios.get(`/api/stores/${storeId}/page/`);
};

export const getStorePageItems = (payload) => {
  return axios.get(
    `/api/stores/${payload.storeId}/item/search/?${
      payload.pageNumber ? `page=${payload.pageNumber}` : ""
    }${payload.perPage ? `&per_page=${payload.perPage}` : ""}${
      payload.category ? `&cat=${payload.category}` : ""
    }${payload.q ? `&q=${payload.q}` : ""}`
  );
};

export const getStorePageItemDetails = (payload) => {
  return axios.get(
    `/api/stores/${payload.storeId}/item/search/${payload.itemId}/`
  );
};

export const addItemToCart = (payload) => {
  return axios.post(`/api/stores/cart/`, payload);
};

export const createNoAuthCart = (payload) => {
  return axios.post(`/api/stores/cart/create/`, payload);
};

export const getUserCart = (payload) => {
  return axios.get(`/api/stores/cart/`);
};

export const removeItemFromCart = (variantId) => {
  return axios.delete(`/api/stores/cart_variant/${variantId}/`);
};

export const placeOrderRequest = (payload) => {
  return axios.post(`/api/stores/order/`, payload);
};

export const getStoreTable = (payload) => {
  return axios.get(
    `api/stores/${payload.storeId}/table/?page=${payload.page}&perPage=${payload.perPage}/`
  );
};

export const createStoreTable = (payload) => {
  return axios.post(`api/stores/${payload.store}/table/`, payload);
};

export const deleteStoreTable = (payload) => {
  return axios.delete(
    `api/stores/${payload.storeId}/table/${payload.tableId}/`
  );
};

export const updateCartVariant = (payload) => {
  return axios.put(
    `api/stores/cart_variant/${payload.variantId}/`,
    payload.formPayload
  );
};

import axios from "../api";

export const changePassword = (data) => {
  return axios.post("/account/change-password", data);
};

export const getConfig = () => {
  return axios.get("/api/v2.0/account/attendee_config_form/");
};

export const updateConfig = (data) => {
  return axios.post("/api/v2.0/account/attendee_config_form/", data);
};

export const getAttendee = () => {
  return axios.get("/api/v2.0/account/attendee_register_form/");
};

export const createAttendee = (data) => {
  return axios.post("/api/v2.0/account/attendee_register_form/", data);
};

export const deleteAttendee = (data) => {
  return axios.delete(
    `/api/v2.0/account/attendee_register_form/?atndId=${data}`
  );
};

export const getContact = () => {
  return axios.get("/api/v2.0/account/contacts/");
};

export const createContact = (data) => {
  return axios.post("/api/v2.0/account/contacts/", data);
};

export const deleteContact = (data) => {
  return axios.delete(`/api/v2.0/account/contacts/?contact_id=${data}`);
};

export const getPromoter = () => {
  return axios.get("/api/v2.0/account/multi_user/");
};

export const createPromoter = (data) => {
  return axios.post("/api/v2.0/account/multi_user/", data);
};

export const deletePromoter = (data) => {
  return axios.delete(`/api/v2.0/account/multi_user/?promoter_id=${data}`);
};

export const changeAccountPassword = (data) => {
  return axios.post(`/api/v2.0/account/change_password/`, data);
};

export const getFlutterWaveBanks = () => {
  return axios.get("/api/wallets/bank_list/");
};

export const closeAccount = (data) => {
  return axios.put("/api/account/close/", data);
};

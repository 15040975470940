import axios from "../api";

export const fetchFuelTransactionById = () => {
  return axios.get("/orders/fuel-station");
};

export const fetchFuelCustomersOrders = () => {
  return axios.get("/orders/fuel-station-income");
};

export const fetchAllFuelStations = () => {
  return axios.get("/api/fuel-station");
};

export const getAllFuelBuyers = () => {
  return axios.get("/api/fuel/customer/");
};

export const createFuelBuyer = (payload) => {
  return axios.post("/fuel-station/buy-fuel/", payload);
};

export const getAllFuelVendors = () => {
  return axios.get("/api/fuel/station/");
};

export const createFuelVendor = (payload) => {
  return axios.post("/api/fuel/station/", payload);
};

export const FuelVendorDash = (vendorId) => {
  return axios.get(`/api/fuel/station/${vendorId}/`);
};

export const FuelVendorProducts = (payload) => {
  return axios.get(
    `/api/fuel/product/${payload.station}/?page=${payload.page}&per_page=${payload.perPage}`
  );
};

export const CreateFuelVendorProduct = (payload) => {
  return axios.post(`/api/fuel/product/`, payload);
};

export const EditFuelVendorProduct = (payload) => {
  return axios.put(
    `/api/fuel/product/edit/${payload.productId}/`,
    payload.formPayload
  );
};

export const DeleteFuelVendorProduct = (productId) => {
  return axios.delete(`/api/fuel/product/delete/${productId}/`);
};

export const FuelVendorStaffs = (payload) => {
  return axios.get(
    `/api/fuel/staff/${payload.station}/?page=${payload.page}&per_page=${payload.perPage}`
  );
};

export const CreateFuelVendorStaff = (payload) => {
  return axios.post(`/api/fuel/staff/`, payload);
};

export const EditFuelVendorStaff = (payload) => {
  return axios.put(
    `/api/fuel/staff/edit/${payload.staffId}/`,
    payload.formPayload
  );
};

export const DeleteFuelVendorStaff = (staffId) => {
  return axios.delete(`/api/fuel/staff/delete/${staffId}/`);
};

export const ResetFuelVendorStaffPin = (payload) => {
  return axios.put(`/api/fuel/staff/pin/reset/`, payload);
};

export const FuelVendorOrders = (payload) => {
  const config = {
    params: {
      per_page: payload.perPage,
      page: payload.page,
      p: payload.q || "30d",
      station: true,
    },
  };
  return axios.get(`/api/fuel/order/${payload.station}/`, config);
};

export const FuelBuyerOrders = (payload) => {
  const config = {
    params: {
      per_page: payload.perPage,
      page: payload.page,
    },
  };
  return axios.get(`/api/fuel/order/${payload.fuelBuyerId}/`, config);
};

export const FuelBuyerOrderDetails = (fuelBuyerId) => {
  return axios.get(`/api/fuel/order/detail/${fuelBuyerId}/`);
};

export const FuelBuyerDash = () => {
  return axios.get(`/api/fuel/customer/`);
};

export const BuyerVehicles = (payload) => {
  return axios.get("/api/fuel/vehicle/", {
    params: {
      page: payload?.page,
      per_page: payload?.perPage,
    },
  });
};

export const CreateBuyerVehicle = (payload) => {
  return axios.post(`/api/fuel/vehicle/`, payload);
};

export const EditBuyerVehicle = (payload) => {
  return axios.put(
    `/api/fuel/vehicle/${payload.vehicleId}/`,
    payload.formPayload
  );
};

export const DeleteBuyerVehicle = (driverId) => {
  return axios.delete(`/api/fuel/driver/${driverId}/`);
};

export const BuyerDrivers = (payload) => {
  return axios.get("/api/fuel/driver/", {
    params:
      payload == "all"
        ? {
            p: "all",
          }
        : {
            page: payload?.page,
            per_page: payload?.perPage,
          },
  });
};

export const CreateBuyerDriver = (payload) => {
  return axios.post(`/api/fuel/driver/`, payload);
};

export const EditBuyerDriver = (payload) => {
  return axios.put(
    `/api/fuel/driver/${payload.driverId}/`,
    payload.formPayload
  );
};

export const DeleteBuyerDriver = (driverId) => {
  return axios.delete(`/api/fuel/driver/${driverId}/`);
};

export const GenerateCode = (payload) => {
  return axios.post(`/api/fuel/purchase/`, payload);
};

export const GetAllStations = () => {
  return axios.get(`/api/fuel/station/?p=all`);
};

export const placeFuelOrderRequest = (payload) => {
  return axios.post(`/api/fuel/payment/`, payload);
};

export const SendCode = (payload) => {
  return axios.post(`/api/fuel/code/send/`, payload);
};

export const RenewCode = (payload) => {
  return axios.put(`/api/fuel/code/renew/`, payload);
};

export const Refund = (payload) => {
  return axios.post(`/api/fuel/order/refund/${payload.code}/`, payload.reason);
};

export const GetFuelPayoutTransactions = (payload) => {
  const config = {
    params: {
      per_page: payload.perPage,
      page: payload.page,
      q: payload.q,
    },
  };
  return axios.get(
    `/api/fuel/station/payout/transactions/${payload.fuelVendorId}/`,
    config
  );
};

export const LinkBankToFLutterWave = ({ payload, fuelVendorId }) => {
  return axios.post(`/api/fuel/station/${fuelVendorId}/banks/`, payload);
};

export const getFuelVendorBank = (stationId) => {
  return axios.get(`/api/fuel/station/${stationId}/banks/`);
};

export const requestFuelPayout = ({ businessId, payload }) => {
  return axios.post(`/api/fuel/station/payout/${businessId}/`, payload);
};

export const unlinkFuelAccount = ({ businessType, businessId }) => {
  return axios.put(`/api/${businessType}/${businessId}/banks/`, {});
};

export const verifyCardPayment = ({ txRef, gateway }) => {
  return axios.get(`/api/fuel/payment/verify/${txRef}/?gateway=${gateway}`);
};

export const initiateCardPayment = (payload) => {
  return axios.post(`/api/fuel/payment/`, payload);
};

export const StorageKeys = {
  Location: "triple7events_location",
};

export function getStorageData(key) {
  try {
    const rawUserData = localStorage.getItem(key);
    if (!rawUserData) {
      return null;
    }

    return JSON.parse(rawUserData);
  } catch (e) {
    // TODO: log to error tool
    return null;
  }
}

export function saveStorageData(key, value) {
  try {
    if (!value || !key) {
      return;
    }

    const stringifyValue = JSON.stringify(value);
    localStorage.setItem(key, stringifyValue);
  } catch (e) {
    // TODO: log to error tool
    return null;
  }
}

export function removeStorageData(key) {
  try {
    if (!key) {
      return;
    }

    localStorage.removeItem(key);
  } catch (e) {
    // TODO: log to error tool
    return null;
  }
}

import { Button } from "components/shared/Button";
import Modal from "components/shared/modal";
import { DEFAULTMODALSTYLE } from "constant";
import { ROUTE_ENUMS } from "enums";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom-v5-compat";
import { toast } from "react-toastify";
import { createFuelBuyerCustomerRequest } from "state/slices/fuel";
import { getOneLinerErrorMessage } from "utilities";

const FuelStationWelcomeView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.users.data);

  const email = user.email;

  const style = {
    backgroundImage: "url(/images/splash-image.png)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  };
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isSelected, setIsSelected] = React.useState({
    title: "",
    path: "",
    accountType: "",
  });

  const selectOptions = [
    {
      title: "Individual Account",
      path: `${ROUTE_ENUMS.DASHBOARD_FUEL_STATION}/setup`,
      accountType: "individual",
    },
    // {
    //   title: "Company Account",
    //   path: `${ROUTE_ENUMS.DASHBOARD_FUEL_STATION}/setup`,
    //   accountType: "customer",
    // },
  ];

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  // const continueSetup = () => navigate(isSelected.path);
  console.log(isSelected.accountType);
  const createFuelstationUser = async () => {
    setIsLoading(true);
    const payload = {
      email,
      accountType: isSelected.accountType,
    };
    const resultAction = await dispatch(
      createFuelBuyerCustomerRequest(payload)
    );
    if (createFuelBuyerCustomerRequest.fulfilled.match(resultAction)) {
      setIsLoading(false);
      toast.success("You are now a fuel station user");
      localStorage.setItem("fuelBuyerLoggedIn", true);
      closeModal();
      setTimeout(() => {
        window.location.reload();
      }, 900);
    } else {
      setIsLoading(false);
      toast.error(
        getOneLinerErrorMessage(resultAction.payload) ||
          resultAction.error?.message ||
          "An error occurred, please try again."
      );
    }
  };

  return (
    <>
      <div className="tw-w-full md:tw-w-[788px] tw-rounded-md tw-bg-white tw-py-10 tw-px-10 lg:tw-w-[788px] tw-mx-auto">
        <h4 className="tw-text-center tw-font-semibold">
          Welcome to Fuel Section
        </h4>

        <div
          className="tw-w-[400px] tw-mx-auto tw-h-[400px]"
          style={style}
        ></div>

        <div className="tw-text-center">
          <p>
            Reduce the fraud associated with drivers falsifying the amount of
            fuel purchased.
          </p>

          <Button
            variant={"primary"}
            className="tw-px-[4em]"
            onClick={openModal}
          >
            Get Started
          </Button>
        </div>

        <Modal
          isActive={isOpen}
          onClose={closeModal}
          classNames={DEFAULTMODALSTYLE}
          childClassName="tw-px-10 tw-py-[2em] tw-w-[95%] tw-mx-auto"
        >
          <div className="tw-text-center">
            <h2 className="tw-font-semibold tw-my-5">User Setup</h2>
            <p className="tw-w-[80%] tw-mx-auto">
              Setup your account that suits your profile and get started
            </p>

            <div className="tw-flex tw-gap-5 tw-my-[2rem]">
              {selectOptions.map((selection, index) => {
                const isActive = isSelected.title === selection.title;
                const activeClasses = isActive
                  ? "tw-border-primary-800"
                  : "tw-border-gray-200";
                return (
                  <div
                    key={index}
                    className={`tw-flex tw-cursor-pointer tw-w-full tw-gap-2 tw-items-center tw-border-[1.9px] ${activeClasses} tw-px-6 tw-py-4 tw-rounded-lg`}
                    onClick={() =>
                      setIsSelected({
                        title: selection.title,
                        path: selection.path,
                        accountType: selection.accountType,
                      })
                    }
                  >
                    <p className="tw-mb-0 tw-text-[14px]">{selection.title}</p>
                    <input
                      type="radio"
                      checked={isActive}
                      onChange={() =>
                        setIsSelected({
                          title: selection.title,
                          path: selection.path,
                          accountType: selection.accountType,
                        })
                      }
                    />
                  </div>
                );
              })}
            </div>

            <Button
              variant="primary"
              className={"tw-w-full tw-mx-auto tw-my-3"}
              onClick={createFuelstationUser}
              disabled={isLoading}
            >
              {isLoading ? "Creating..." : "Lets Go"}
            </Button>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default FuelStationWelcomeView;

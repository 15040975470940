import axios from "../api";

export const fetchAllUsers = (data) => {
  return axios.get("/users", {
    params: { ...data, append: "role" },
  });
};

export const createUser = (data) => {
  return axios.post("/users", data);
};

export const fetchUser = (id) => {
  return axios.get(`/users/${id}`);
};

export const updateUser = (id, data) => {
  return axios.patch(`/users/${id}`, data);
};

export const enableUser = (id) => {
  return axios.post(`/users/${id}/enable`);
};

export const disableUser = (id) => {
  return axios.post(`/users/${id}/disable`);
};

export const getProfile = (id) => {
  return axios.get(`/api/v2.0/account/user_profile/`, {
    params: { source: "web" },
  });
};

export const updateProfile = (data) => {
  const config = {
    params: { source: "web", type: "profile" },
  };

  return axios.put(
    `/api/v2.0/account/user_profile/`,
    {
      ...data,
    },
    config
  );
};

export const deleteAddress = (data) => {
  const config = {
    params: { source: "web", addr_id: data },
  };

  return axios.delete(`/api/v2.0/account/user_profile/`, config);
};

export const addAddress = (data, type) => {
  const config = {
    params: { source: "web", type: type },
  };

  return axios.put(`/api/v2.0/account/user_profile/`, { ...data }, config);
};

export const editAddress = (data, type) => {
  const config = {
    params: { source: "web", type: type },
  };

  return axios.put(`/api/v2.0/account/user_profile/`, { ...data }, config);
};

export const uploadPicture = (data) => {
  return axios.post("/api/v2.0/account/user_profile_photo/", data);
};

export const defaultAddy = (id) => {
  return axios.post(`/api/v2.0/account/set_default_address/${id}/`);
};

export const contactUs = (data) => {
  return axios.post(`/api/v2.0/account/contact-us/`, data);
};

export const getWalletTransactions = (payload) => {
  const config = {
    params: {
      per_page: payload.perPage,
      page: payload.pageNumber,
      q: payload.q,
    },
  };
  return axios.get(`/api/wallets/transactions/${payload.walletId}/`, config);
};

export const getBusinessWalletTransactions = ({
  perPage,
  pageNumber,
  q,
  walletId,
  type,
  typeId,
}) => {
  const config = {
    params: type
      ? {
          per_page: perPage,
          page: pageNumber,
          q,
          [type]: typeId,
        }
      : {
          per_page: perPage,
          page: pageNumber,
          q,
        },
  };
  return axios.get(`/api/wallets/transactions/vendor/${walletId}/`, config);
};

export const getWalletInfo = (type) => {
  const config = {
    params: {
      type,
    },
  };
  return axios.get(`/api/wallets/`, type && config);
};

export const getVendorWalletBalance = ({ businessId, walletId }) => {
  const config = {
    params: businessId,
  };
  return axios.get(`/api/wallets/vendor/${walletId}/balances/`, config);
};

export const activateWallet = (data) => {
  return axios.post(`/api/wallets/activate/`, data);
};

export const initiateWalletTransaction = (data) => {
  return axios.post(
    `/api/wallets/transaction/initialize/${data.id}/`,
    data.formPayload
  );
};

export const verifyTransaction = (data) => {
  return axios.get(`/api/wallets/transaction/verify/${data}/`);
};

export const verifyCardStoreTransaction = ({ txRef, gateway }) => {
  return axios.get(
    `/api/stores/order/payment/${txRef}/verify/?gateway=${gateway}`
  );
};

export const changePinOne = (payload) => {
  return axios.post(
    `/api/wallets/change_pin/${payload.walletId}/`,
    payload.formPayload
  );
};

export const changePinTwo = (payload) => {
  return axios.post(
    `/api/wallets/change_pin/${payload.walletId}/`,
    payload.formPayload
  );
};

export const getChangePin = (walletId) => {
  return axios.get(`/api/wallets/change_pin/${walletId}/`);
};

export const getWalletTransactionPDF = (type) => {
  const config = {
    params: {
      type,
    },
  };
  return axios.get(`/api/wallets/transaction/pdf/`, type && config);
};

export const getAvailableFee = ({ businessType, businessId }) => {
  return axios.get(`/api/${businessType}/payout/${businessId}/`);
};

export const getVendorBank = ({ businessType, businessId }) => {
  return axios.get(
    `/api/${businessType}${businessId ? `/${businessId}` : ""}/banks/`
  );
};

export const addVendorBank = ({ businessType, businessId, payload }) => {
  return axios.post(
    `/api/${businessType}/${businessId ? `${businessId}/` : ""}banks/`,
    payload
  );
};

export const requestPayout = ({ businessType, businessId, payload }) => {
  return axios.post(
    `/api/${businessType}/payout/${businessId ? `${businessId}/` : ""}`,
    payload
  );
};

export const unlinkBusinessAccount = ({ businessType, businessId }) => {
  return axios.put(
    `/api/${businessType}/${businessId ? `${businessId}/` : ""}banks/`,
    {}
  );
};

import React from "react";

const SelectFuelStationPane = ({ src, name, price, onSelect, isSelected }) => {
  const fallbackImage = "/images/logo.svg";
  const isValidImageSrc =
    src && !src.includes("https://t7e-v2-s3.s3.eu-west-2.amazonaws.com/");

  return (
    <div
      className="tw-flex tw-items-center tw-justify-between tw-my-5 tw-cursor-pointer"
      onClick={onSelect}
    >
      <div className="tw-m-0 tw-flex tw-items-center tw-gap-5 ">
        <input
          type="checkbox"
          className="tw-cursor-pointer"
          checked={isSelected}
        />
        <div>
          <img
            className="tw-w-[50px] tw-h-[50px] tw-rounded-full object-cover transition-all duration-300"
            src={!isValidImageSrc ? src : fallbackImage}
            alt={name}
          />
        </div>
      </div>
      <div className=" tw-text-black tw-m-0 tw-flex tw-items-center tw-gap-5">
        <p className="tw-font-600 tw-text-sm sm:tw-text-sm md:tw-text-[14px] lg:tw-text-[14px]">
          {name}
        </p>
        <p className="tw-font-600 tw-text-sm sm:tw-text-sm md:tw-text-[14px] lg:tw-text-[14px]">
          {price}/ltr
        </p>
      </div>
    </div>
  );
};

export default SelectFuelStationPane;

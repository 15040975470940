import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Ticket } from "services/network";

const initialState = {
  ticketLoading: false,
  ticketError: null,
  data: {},
};

export const fetchMyTicketRequest = createAsyncThunk(
  "tickets/my",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Ticket.fetchMyTickets();
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const fetchTicketDetailRequest = createAsyncThunk(
  "tickets/details",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Ticket.fetchTicketsDetails(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const transferTicketRequest = createAsyncThunk(
  "tickets/transferTicket",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Ticket.transferTickets(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const acceptTicketRequest = createAsyncThunk(
  "tickets/acceptTicket",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Ticket.acceptTickets(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

export const cancelTicketRequest = createAsyncThunk(
  "tickets/cancelTicket",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await Ticket.cancelTickets(payload);
      return response.data;
    } catch (err) {
      if (!err.response) throw err;
      return rejectWithValue(err.response.data);
    }
  }
);

const ticketsSlice = createSlice({
  name: "tickets",
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchTicketDetailRequest.fulfilled]: (state, action) => {
      state.data = action.payload;
    },
  },
});

export default ticketsSlice.reducer;

import VehiclesAndDrivers from "../../components/shared/drivers/vehiclesAndDrivers";
import FuelStationWebcomeView from "./welcomeView";
import FuelStationDashboardLayout from "components/layout/FuelStation/fuelStationDashboardContainer";
import TableSkeleton from "components/shared/TableSkeleton";
import { NoTransactionHistory } from "components/shared/fallback/emptyData";
import DefaultTable from "components/shared/tables/defaultTable";
import { TABLE_STYLE_ENUMS } from "enums/styleEnums";
import usePagination from "hooks/usePagination";
import { findCurrencySymbolByCode } from "lib/country";
import { thousandFormatter } from "lib/number";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { fetchFuelBuyerCustomerDashboard } from "services/network-v2/fuel";
import {
  fetchBuyerDashboardRequest,
  fetchFuelStationTransactionsRequest,
} from "state/slices/fuel";

const transactionHistoryTableHeader = [
  "S/N",
  "Amount",
  "Status",
  "Date",
  "Time",
];

const FuelBuyerDashboard = () => {
  const { user } = useSelector((state) => state.users.data);
  const isBuyerSignedIn = localStorage.getItem("fuelBuyerLoggedIn") !== null;

  const { data: customerData } = usePagination({
    request: fetchBuyerDashboardRequest,
    dataSelector: "",
    customQueryPayload: {},
  });

  const { isActive } = customerData || {};

  const { data, loading: isLoading } = usePagination({
    request: fetchFuelStationTransactionsRequest,
    dataSelector: "",
    customQueryPayload: {},
  });

  const { wallets } = useSelector((state) => state.users.data);

  return (
    <>
      {!isActive ? (
        <FuelStationWebcomeView />
      ) : (
        <FuelStationDashboardLayout others={<VehiclesAndDrivers />}>
          <div className="tw-relative tw-overflow-x-auto">
            {data || data?.length > 0 ? (
              <div>
                {!isLoading ? (
                  <DefaultTable headerData={transactionHistoryTableHeader}>
                    <tbody className={TABLE_STYLE_ENUMS.TABLE_BODY}>
                      {data?.map((data, index) => (
                        <tr key={index}>
                          <td className={TABLE_STYLE_ENUMS.TABLE_DATA}>
                            {index + 1}
                          </td>
                          <td className={TABLE_STYLE_ENUMS.TABLE_DATA}>
                            {findCurrencySymbolByCode(wallets[0]?.currency)}{" "}
                            {thousandFormatter(data?.amount)}
                          </td>
                          <td className={TABLE_STYLE_ENUMS.TABLE_DATA}>
                            {data?.status}
                          </td>
                          <td className={TABLE_STYLE_ENUMS.TABLE_DATA}>
                            {moment(data?.updatedAt).format("MMMM Do YYYY")}
                          </td>
                          <td className={TABLE_STYLE_ENUMS.TABLE_DATA}>
                            {moment(data?.updatedAt).format("LT")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </DefaultTable>
                ) : (
                  <TableSkeleton rows={10} columns={7} />
                )}
              </div>
            ) : (
              <NoTransactionHistory />
            )}
          </div>
        </FuelStationDashboardLayout>
      )}
    </>
  );
};

export default FuelBuyerDashboard;

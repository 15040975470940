import { TableTrashIcon, TableEditIcon } from "assets/icons";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const TableActionIcons = ({ onDelete, editPath, withDelete = true }) => {
  return (
    <div className="tw-flex tw-gap-4">
      <Link to={editPath}>
        <TableEditIcon />
      </Link>

      {withDelete && (
        <div className="tw-cursor-pointer" onClick={onDelete}>
          <TableTrashIcon />
        </div>
      )}
    </div>
  );
};

export default TableActionIcons;

TableActionIcons.prototype = {
  onDelete: PropTypes.func.isRequired,
  editPath: PropTypes.string.isRequired,
};

import axios from "../api";

export const fetchMyTickets = (data) => {
  return axios.get("/api/v2.0/event/my_tickets/", {
    params: data,
  });
};

export const fetchTicketsDetails = (id) => {
  return axios.get(`/api/v2.0/event/my_tickets/${id}/`);
};

export const transferTickets = (payload) => {
  return axios.post(`/api/v2.0/event/ticket_send/`, payload);
};

export const acceptTickets = (id) => {
  return axios.post(`/api/v2.0/event/transfer_tickets/${id}/`);
};

export const cancelTickets = (id) => {
  return axios.delete(`/api/v2.0/event/transfer_tickets/${id}/`);
};

import axios from "../api";

export const signUp = (data) => {
  return axios.post("/api/v2.0/account/signup/", data);
};

export const login = (data) => {
  return axios.post("/api/v2.0/account/login/", data);
};

export const socialLogin = (data) => {
  return axios.post("/api/v2.0/account/social_auth/", data);
};

export const forgotPassword = (data) => {
  return axios.post("/api/v2.0/account/forgot_password/", data);
};

export const resetPassword = (data) => {
  return axios.put("/api/v2.0/account/forgot_password/", data);
};

export const getUser = (data) => {
  return axios.get("/auth/user/", data);
};

export const logout = () => {
  return axios.post("/auth/logout/");
};
